<template>
  <div class="container" id="app">
    <div class="d-flex justify-content-center" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="!loading">
      <div>
        <v-chart class="voeding-chart" :option="voedingChartOption" autoresize />
      </div>
      <div>
        <table class="table">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead class="table-dark">
            <tr>
              <th>Datum</th>
              <th>Aantal</th>
              <th>Afdeling</th>
              <th>Item</th>
              <th>Ontvanger</th>
              <th>Maat</th>
              <th>Opmerking</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in gekregen" v-bind:key="item.ID || item.intID"
              v-bind:class="rowColors[getDate(item.datum)] ? 'stripe' : ''">
              <td style="width: 160px">
                {{ getDate(item.datum) }}
              </td>
              <td style="width: 70px">
                {{ item.aantal }}
              </td>
              <td>
                {{ sentenceCase(item.object.categorie.afdeling) }}
              </td>
              <td>
                {{ item.object.naam }}
              </td>
              <td>
                {{ item.ontvanger }}
              </td>
              <td>
                {{ item.maat ? item.maat.naam : "" }}
              </td>
              <td>
                {{ item.opmerking }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-none">{{ forceRender }}</div>

      <paginate v-model="pageNumber" :page-count="pageCount" :page-range="3" :margin-pages="0"
        containerClass="pagination" page-class="page-item" page-link-class="page-link" prev-text="Vorige"
        prev-class="page-item" prev-link-class="page-link" next-text="Volgende" next-class="page-item"
        next-link-class="page-link" :click-handler="pageClickCallback">
      </paginate>
    </div>
  </div>
</template>

<script>
import { ledenService } from "../../_services/leden.service";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { ScatterChart } from "echarts/charts";
import {
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  ToolboxComponent,
  DataZoomComponent,
  SingleAxisComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  ScatterChart,
  SingleAxisComponent,
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  ToolboxComponent,
  DataZoomComponent
]);


function sentenceCase(str) {
  if ((str === null) || (str === ''))
    return false;
  else
    str = str.toString();

  return str.replace(/\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() +
        txt.substr(1).toLowerCase();
    });
}

export default {
  props: ["id"],
  components: {
    VChart,
  },
  data: function () {
    return {
      pageCount: 0,
      gekregen: [],
      pageNumber: 1,
      forceRender: 0,
      loading: true,
      sentenceCase,
      voedingChartOption: {},
    };
  },
  computed: {
    rowColors: function () {
      let out = {};

      let prevDate = null;
      let prevColor = true; // true = colored line
      for (let item of this.gekregen) {
        if (this.getDate(prevDate) != this.getDate(item.datum)) {
          prevColor = !prevColor;
          prevDate = this.getDate(item.datum);

          out[this.getDate(item.datum)] = prevColor;
        }
      }

      return out;
    },
  },
  provide: {
    [THEME_KEY]: "light"
  },
  methods: {
    getDate: (d) => {
      const date = new Date(d);
      return date.toLocaleDateString("sv-SV", {timeZone: "Europe/Brussels"})
    },
    pageClickCallback: async function (num) {
      this.loadPage(num);
    },
    loadPage: async function (num) {
      this.loading = true;
      this.pageNumber = num;

      const { data, totalEntries } = await ledenService.getGekregen(
        this.id,
        num
      );

      this.gekregen = data;

      this.pageCount = Math.ceil(totalEntries / 20);
      if (this.pageCount < 1) {
        this.pageCount = 1;
      }

      this.loading = false;
    },
    loadVoedingChart: async function () {
      const data = await ledenService.getGekregenVoeding(this.id);

      const days = []
      // get every monday of past year
      for (let i = 0; i < 52; i++) {
        const date = new Date();
        date.setDate(date.getDate() - (i * 7));
        date.setDate(date.getDate() - date.getDay());
        days.push(date.toLocaleDateString("sv-SV"), {timeZone: "Europe/Brussels"})
      }

      days.reverse()

      const chartData = days.map(d => [d, 0])
      console.log(data)
      Object.keys(data).forEach((dateStr) => {
        const date = new Date(dateStr);
        // get monday of week
        date.setDate(date.getDate() - date.getDay());
        const day = date.toLocaleDateString("sv-SV", {timeZone: "Europe/Brussels"});
        const index = days.indexOf(day);
        if (index > -1) {
          chartData[index][1] += 1;
        }
      })

      console.log(chartData)

      this.voedingChartOption = {
        tooltip: {
          left: 150,
          position: 'top'
        },
        title: [
          {
            text: "Voeding"
          }
        ],
        singleAxis: [
          {
            left: 150,
            top: '5%',
            boundaryGap: true,
            height: 100 / 7 - 10 + '%',
            type: 'category',
            data: days,
            axisLabel: {
              //show: false,
              rotate: 40,
              interval: 3
            }
          }
        ],
        series: {
          singleAxisIndex: 0,
          coordinateSystem: 'singleAxis',
          type: 'scatter',
          data: chartData,
          symbolSize: function (dataItem) {
            return dataItem[1] * 20;
          }
        }
      }
    }
  },

  created: async function () {
    this.loading = true;
    try {
      await this.loadPage(1);
      this.loadVoedingChart();
    } catch (e) {
      this.$Simplert.open({
        title: "Error!",
        message: e,
        type: "error",
        customCloseBtnText: "Sluiten",
      });

      return;
    }
    this.loading = false;
  },
};
</script>

<style
  src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
>

</style>

<style scoped>
.voeding-chart {
  height: 200px;
  width: 100%;
}
</style>